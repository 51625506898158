

import React from 'react';
import { graphql } from 'gatsby';
import Link from 'gatsby-link';
import Img from 'gatsby-image';
import styled from 'styled-components';

import { FaTags, FaMapMarker } from 'react-icons/fa';

import Layout from 'components/Layout';
import SEO from 'components/SEO';
import Divider from 'components/ui/Divider';
import ContactCard from 'components/ui/ContactCard';
import FormatHtml from 'components/utils/FormatHtml';

import * as Styled from './styles';

const FTag = styled(FaTags)`
  position: absolute;
  top: .85rem;
  left: 1.5rem;
`;

const FMapMarker = styled(FaMapMarker)`
  position: absolute;
  top: .85rem;
  left: 1.5rem;
`;

const BlogPost = ({ data, pageContext }) => {
  const post = data.markdownRemark;

  const tags = post.frontmatter.tags.map((tag, i) => {
    return (
      <Link to={`/tags/${tag.toLowerCase()}`} key={`tag${i}`}>
        <Styled.Tag>
          <p><FTag /> {tag}</p>
        </Styled.Tag>
      </Link>
    )
  });
  
  const caption = post.frontmatter.covercredit ? <Styled.Caption>{`Credit: ` + post.frontmatter.covercredit}</Styled.Caption> : <></>
  
  
  const { previous, next } = pageContext;
  console.log(previous);

  const LeftLink = next ? (
  <Link to={next.fields.slug} rel="next">
    <Styled.Card>
      <Styled.Category className={next.frontmatter.topic}>
        <h5>← Next</h5>
        <p>{next.frontmatter.levelIcon}</p>
      </Styled.Category>
      <Styled.Content>
        <Styled.Title><span>{next.frontmatter.title}</span></Styled.Title>
      </Styled.Content>
    </Styled.Card>
  </Link>
  ) : <></>;
  
  const RightLink = previous ? (
    <Link to={previous.fields.slug} rel="previous">
      <Styled.Card className="right">
        <Styled.Category className={previous.frontmatter.topic}>
          <h5>Previous →</h5>
          <p>{previous.frontmatter.levelIcon}</p>
        </Styled.Category>
        <Styled.Content>
          <Styled.Title><span>{previous.frontmatter.title}</span></Styled.Title>
        </Styled.Content>
      </Styled.Card>
    </Link>
    ) : <></>;

  
  return (
    <Layout>
      <SEO title={post.frontmatter.title} description={pageContext.data.description}/>
      <Styled.BlogPage section>
        <Styled.Titles>
          <h1>
            {post.frontmatter.title}
          </h1>
          <time>
            {post.frontmatter.date}
          </time>
        </Styled.Titles>
        <Divider page="blog"/>
        <Styled.CentralContainer>
          <Styled.TagContainer>
          <Link to={`/categories/${post.frontmatter.topic.toLowerCase()}`} key={`tag`}>
            <Styled.Tag>
              <p><FMapMarker /> {post.frontmatter.topic}</p>
            </Styled.Tag>
          </Link>
            {tags}
          </Styled.TagContainer>
          <Styled.Image>
            <Img fluid={post.frontmatter.cover.childImageSharp.fluid} alt={post.frontmatter.title} />
          </Styled.Image>
          {caption}
          <FormatHtml content={post.html} classContent="post-content" />
          <ContactCard />
          <Styled.Links>
            {LeftLink}
            {RightLink}
          </Styled.Links>
        </Styled.CentralContainer>
      </Styled.BlogPage>
    </Layout>
  );
};

export default BlogPost;

export const query = graphql`
  query BlogPostBySlug($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        date(formatString: "MMM DD, YYYY")
        description
        tags
        category
        topic
        covercredit
        cover {
          childImageSharp {
            fluid(maxWidth: 200) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`;
