import styled from 'styled-components';

export const CentralContainer = styled.div`
    padding-top: 12rem;
    margin: 0 auto;
    position: relative;
    z-index: 1;
    font-display: swap;

    .gatsby-resp-image-wrapper{
        padding: 2rem 5rem;
    }

    p, h1, h2, h3, h4, h5, h6, hr {
        position: relative;
        z-index: 1;
    }

    .post-content img {
        max-width: 100%;
        margin-top: 3rem;
        
        @media (max-width: 414px) {
            padding: 0;
        }
    }

    .post-content iframe {
        max-width: 100%;
        margin-top: 3rem;
    }

    @media (max-width: 800px) {
        padding: 5rem 2rem 0;
    }

    &:before,
    &:after {
        display: block;
        content: '';
        clear: both;
    }

    padding: 1em 0 4em 0;
    line-height: 1.6em;
    max-width: 63rem;
    margin: 0 auto;
    margin-top: 19rem;

    @media (max-width: 450px) {
        margin-top: 6rem;
    }

    @media (min-width: 450px) and (max-width: 800px) {
        margin-top: 6rem;
    }

    @media (min-width: 800px) {
        margin-top: 6rem;
    }

    .narrow {
        max-width: 32rem;
        margin: 0 auto !important;
    }

`;


export const BlogPage = styled.section`

    h1 {
        padding: 12rem 0 .67rem;
        font-size: 3.2rem;
        @media (max-width: 414px) {
            margin: 0 2rem;
        }
    }

    h2 {
        font-size: 3.2rem;
        font-family: 'Kosugi', sans-serif;
        padding-top: 4rem;
    }

    h4 {
        font-size: 2.5rem;
        font-family: 'Source', sans-serif;
        text-decoration: underline;
        padding-left: 2rem; 
    }

    .caption {
        text-align: center;
        font-style: italic;
        font-size: 1.8rem !important;
        color: dimgray;
        margin-top: .5rem !important;
    }

    .credit {
        color: darkgray;
        font-size: 1.7rem;
    }

    hr {
        border: 3px solid #c3e0c3;
        border-radius: 6px;
        margin: 6rem 0;
    }

    .mt-100 {
        margin-top: 16rem;
    }

    .clear {
        clear: both;
    }

    .post-content {

        margin-bottom: 8rem;

        em {
            font-style: italic;
        }

        ol {
            list-style-type: decimal;
        }

        code {
            font-family: monospace !important;
        }

        pre {
            padding: 2rem;
            background-color: #f4f3f3;
            border-radius: 5px;

            @media (max-width: 400px) {
                padding: .5rem;
                overflow-x: auto; 
            }
            code {
                font-family: Consolas, monospace !important;
                color: #4e3838;
                max-width: 100%;
                line-height: 2rem;
                font-size: 1.5rem;
                overflow-wrap: break-word;
                white-space: pre-wrap;
                word-wrap: break-word;

                @media (max-width: 400px) {
                    font-size: 1.3rem;
                    line-height: 2rem;
                    white-space: pre; 
                }

                &.language-text {
                    font-family: 'Source', sans-serif !important;
                    font-size: 1.7rem;
                    line-height: 2.2rem;
                }
            }

            
         }

        
        ul {
            margin-top: 1.6rem;
            li {
                line-height: 3rem;
                font-size: 2rem;
                list-style-type: disc;
                @media (max-width: 414px) {
                    line-height: 2.8rem;
                    font-size: 1.8rem;
                }
            }
        }

        ol {
            margin-top: 1.6rem;
            li {
                line-height: 3rem;
                font-size: 2rem;
                list-style-type: decimal;
                @media (max-width: 414px) {
                    line-height: 2.8rem;
                    font-size: 1.8rem;
                }
            }
        }

        h1, h2, h3, h4, h5, h6, p, li {
            font-family: 'Source', sans-serif;
        }

        h2, h3, h4, strong {
            font-family: 'Source-Bold'; 
        }

        h2 {
            margin: 2.4rem 0 1rem;
            font-size: 2.8rem;
            line-height: 1.4em;
        }

        h3 {
            margin: 2.4rem 0 1rem;
            font-size: 2.4rem;
            line-height: 1.4em;
        }

        h4 {
            margin: 2.4rem 0 1rem;
            font-size: 1.2rem;
        }

        p {
            margin: 1.6rem 0 0;
            line-height: 3rem;
            font-size: 2rem;

            @media (max-width: 414px) {
                line-height: 2.8rem;
                font-size: 1.8rem;
            }
        }

        ul,ol {
            padding-left: 5rem;
        }

        blockquote {
            margin: 3rem 0 3rem;
            padding: 0 0 0 2em;
            line-height: 1.2em;
            font-size: 2rem;
            border-left: 3px #4CAF50 solid;
            p {
                font-size: 3rem;
                line-height: 3.8rem;
                color: dimgray;
            }
        }

        pre {
            margin: 2rem 0 0;
        }

        a {
            color: green;
            text-decoration: underline;
        }
    }
`;

export const Titles = styled.section`
    max-width: 90rem;
    margin: 0 auto; 
    text-align: center;
    position: relative;
    z-index: 2;
    
    h1 {
        font-family: 'Source-Bold'; 
        font-size: 6rem;
    }

    @media (max-width: 800px) {
        h1 { 
            font-size: 5rem;
            line-height: 5rem;
        }
    }

    time {
        font-family: 'Kosugi';
        font-size: 2rem;
    }
`;

export const TagContainer = styled.div`
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin-top: 3rem;

   a:first-child  > div {
        background-color: rgba(170,170,170,.5);
        transition: background-color .2s ease-in-out;

        &:hover {
            background-color: rgba(190,190,190,.8)
        }

        @media (max-width: 414px) {
            background-color: rgba(190,190,190,1);
            &:hover {
                background-color: rgba(190,190,190,.8)
            }
        }
    }
`;

export const Tag = styled.div`
    margin: .8rem 1rem;
    background-color: grey;
    border-radius: 3rem;
    background-color: rgba(255,255,255,.5);
    transition: background-color .3s ease-in-out;

    &:hover {
        background-color: rgba(255,255,255,.9)
    }

    @media (max-width: 414px) {
        margin: .6rem;
        background-color: rgba(178, 235, 190, .5);
    }

    p {
        font-family: 'Source', sans-serif;
        padding: .07rem 2rem .07rem 3.6rem;
        line-height: 3rem;
        font-size: 1.5rem;
        color: black;
        text-decoration: none;
        .svg {
            position: absolute;
            top: .9rem;
            text-decoration: none;
        }
    }
`;

export const Image = styled.figure`
    padding-top: 6rem;
    width: 63rem;
    max-width: 100%;
    margin: 0 auto;
    max-height: 54rem;
`;

export const Caption = styled.p`
    font-family: 'Source', sans-serif;
    margin: 1.6rem 0 0;
    line-height: 3rem;
    font-size: 1.7rem;
    text-align: center;
    font-style: italic;
    color: dimgray;
    margin-top: .5rem !important;
`;

export const Links = styled.div`
    max-width: 65rem;
    margin: 0 auto;
    
    padding: 6rem 0 0 0;
    line-height: 2.5rem;
    font-family: 'Source', sans-serif;
    font-size: 1.8rem;

    @media (min-width: 414px) {
        display: flex;
    justify-content: space-between;
    }

    a {
        width: 50%;

        @media (max-width: 414px) {
            width: 100%;
        }
    }

    .prev {
        text-align: left;
        width: 35%;

        @media (max-width: 414px) {
            width: 100%;
        }
    }

    .next {
        text-align: right;
        width: 35%;
        @media (max-width: 414px) {
            width: 46%;
        }
    }
`;



export const Card = styled.div`
    margin: 0 auto;
    background-color: #FFF;
    height: 100%;
    box-shadow: 0 1.5rem 4rem rgba(0, 0, 0, .3);
    border-radius: 4px;
    margin: 0 auto;
    width: 90%;
    position: relative;
    transition: all .4s ease;

    &.right {
        text-align: right;

        h5 {
            right: 2rem;
            left: 4.7rem !important;
        }
    }

    &:hover {
      transform: scale(1.04);
      box-shadow: 0 2.5rem 6rem rgba(0, 0, 0, .3);
    }

    &:active {
      transform: scale(0.98);
    }

    .Philosophy {
        background-color: #BA2B4A;
    }

    .Tech {
        background-color: #337995;
    }

    .Statistics {
        background-color: #337995;
    }

    .Careers {
        background-color:#E9DA65;
    }

    .Science {
        background-color: #00D27D;
    }

    .Business {
        background-color: #CF804A;
    }

    .General {
        background-color: #32373B;
    }

    .Equality {
        background-color: #C7467E;
    }
`;

export const Category = styled.div`
    height: 50px;
    border-radius: 4px 4px 0 0;
    
    h5, h5:visited, h5:active, h5:hover{
        color: #FFF;
        text-decoration: none !important;
        font-family: Source;
        font-size: 1.8rem;
        line-height: 3rem;
        position: absolute;
        top: 1.1rem;
        left: 2rem;
        max-width: 75%;
        color: white;
    }

    p {
    position: absolute;
    right: 1.4rem;
    top: 0;
    height: 7rem;
    padding-top: 1.4rem;
    color: white;

    svg {
      font-size: 2.5rem;
    }
    }
`;

export const Content = styled.div`
`;

export const Title = styled.h3`
    font-size: 2.1rem;
    font-family: 'Source-Bold', sans-serif; 
    line-height: 3.25rem;
    color: green; 
    text-decoration: green;
    margin: 1.5rem 2rem;

    span, span:visited {
        color: #000; 
        text-decoration: #000;
    }

    @media (max-width: 414px) {
        padding-bottom: 1.5rem;
    }
`;